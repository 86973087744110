import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAudience } from '../utils/Actions';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Golfportfolio from '../components/Golfportfolio';
//import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

const features = [
    {
        name: 'Curbing profiles',
        description:
            'Continuously extruded on site, our decorative concrete curbs follow the grade. The shapes are precisely what they need to be, with perfect edging dividing landscape design and golf turf.',
        href: '/profiles',
        icon: CheckCircleIcon,
    },
    {
        name: 'Synthetic grass',
        description:
            'Designed to perform in the ever-changing Canadian climate, the use of breakthrough materials and processes in manufacturing ensures that they will look and feel just as realistic and beautiful for years to come.',
        href: '/grass',
        icon: CheckCircleIcon,
    },
    {
        name: 'Custom putting greens',
        description:
            'Putting greens are the ideal choice for golf courses, and for commercial and residential applications of all sizes. We have a wide array of choices when it comes to synthetic grass for putting greens.',
        href: '/grass',
        icon: CheckCircleIcon,
    },
    {
        name: 'Certified installers',
        description:
            'Curb-Ease® teams are fully equipped and prepared to install hundreds of feet of curbing per day, ensuring prompt completion of large projects and minimizing inconvenience to our customers. We highly value and respect professional relationships and continuously thrive on industry recommendations.',
        href: '/',
        icon: CheckCircleIcon,
    },
];

function Golf({ formRef }) {

    const state = useSelector(state => state);
    const audience = useSelector(state => state.lead.audience);
    const dispatch = useDispatch();

    useEffect(() => { // update the state when the component is mounted
        dispatch(updateAudience({ ...audience, golf: true }));
        return () => { // cleanup once the component is unmounted
            dispatch(updateAudience({ ...audience, golf: false }));
        };
    }, []);

    const scrolltoForm = (e) => {
        // Preventing the default behavior of the form submit (which is to refresh the page)
        e.preventDefault();
        if (formRef && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-left">
                        <h2 className="text-4xl font-semibold leading-7 text-green-700">Golf Club and Golf Course Solutions</h2>
                        <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                            Everything you need to grow your golf business even further.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Curb-Ease® provides a variety of decorative concrete edging and synthethic grass solutions for golf.
                            We partner with the leading architects to construct custom putting greens and mini golf courses.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2 px-16 lg:px-32">
                            {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-lg font-bold leading-7 text-gray-900">
                                        <feature.icon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col ml-2 leading-7 text-gray-600">
                                        <p className="flex-auto">{feature.description}</p>
                                        <p className="mt-6">
                                            <a href={feature.href}
                                                role="button"
                                                onClick={feature.href === '/' ? scrolltoForm : undefined}
                                                className="text-sm font-semibold leading-6 text-green-700 hover:text-green-800">
                                                Learn more <span aria-hidden="true">→</span>
                                            </a>
                                        </p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            <Golfportfolio />
        </>

    )

};

export default Golf;