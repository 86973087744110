import React from 'react';

export default function Color() {

    return (

        <ul className="space-y-3">
            <div>
                <li className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                    <img
                        src={require('../assets/colors/color-chart.jpg')}
                        alt={'Curber\'s Choice® Premium Integral Colors'}
                    />
                </li>
            </div>
        </ul>

    )
}
