import React, { useState } from 'react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

const files = [
    {
        title: 'Curbs with autumn colors',
        source:
            `${require('../assets/gallery/i37.jpg')}`,
    },
    {
        title: 'Decorative concrete tree circles',
        source:
            `${require('../assets/gallery/i21.jpg')}`,
    },
    {
        title: 'Decorative concrete curbs around playground',
        source:
            `${require('../assets/gallery/i22.jpg')}`,
    },
    {
        title: 'Edged fire pit by the side of the lake',
        source:
            `${require('../assets/gallery/i36.jpg')}`,
    },
    {
        title: 'Decorative garden concrete edging installed on interlocking brick walkway',
        source:
            `${require('../assets/gallery/i23.jpg')}`,
    },
    {
        title: 'Photo 24',
        source:
            `${require('../assets/gallery/i24.jpg')}`,
    },
    {
        title: 'Photo 26',
        source:
            `${require('../assets/gallery/i26.jpg')}`,
    },
    {
        title: 'Photo 27',
        source:
            `${require('../assets/gallery/i27.jpg')}`,
    },
    {
        title: 'Photo 28',
        source:
            `${require('../assets/gallery/i28.jpg')}`,
    },
    {
        title: 'Photo 30',
        source:
            `${require('../assets/gallery/i30.jpg')}`,
    },
    {
        title: 'Photo 31',
        source:
            `${require('../assets/gallery/i31.jpg')}`,
    },
    {
        title: 'Photo 32',
        source:
            `${require('../assets/gallery/i32.jpg')}`,
    },
    {
        title: 'Photo 33',
        source:
            `${require('../assets/gallery/i33.jpg')}`,
    },
    {
        title: 'Photo 34',
        source:
            `${require('../assets/gallery/i34.jpg')}`,
    },
    {
        title: 'Photo 35',
        source:
            `${require('../assets/gallery/i35.jpg')}`,
    },
    {
        title: 'Photo 5',
        source:
            `${require('../assets/gallery/5.jpeg')}`,
    },
    {
        title: 'Photo 7',
        source:
            `${require('../assets/gallery/7.jpeg')}`,
    },
    {
        title: 'Photo 8',
        source:
            `${require('../assets/gallery/8.jpeg')}`,
    },
    {
        title: 'Photo 9',
        source:
            `${require('../assets/gallery/9.jpeg')}`,
    },
    {
        title: 'Photo 10',
        source:
            `${require('../assets/gallery/10.jpeg')}`,
    },
]

function Portfolio() {

    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const openPopover = (index) => {
        setSelectedImage(files[index].source);
        setCurrentIndex(index);
        setIsPopoverOpen(true);
    };

    const closePopover = () => {
        setSelectedImage(null);
        setCurrentIndex(null);
        setIsPopoverOpen(false);
    };

    const nextImage = () => {
        const nextIndex = currentIndex === files.length - 1 ? 0 : currentIndex + 1;
        setSelectedImage(files[nextIndex].source);
        setCurrentIndex(nextIndex);
    };

    const previousImage = () => {
        const previousIndex = currentIndex === 0 ? files.length - 1 : currentIndex - 1;
        setSelectedImage(files[previousIndex].source);
        setCurrentIndex(previousIndex);
    };

    return (
        <>
            <section className="bg-green-50 p-4 mb-8">
                <h2 className="text-2xl font-bold text-center">Our Projects</h2>
                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 px-16 lg:px-32 max-h-64 overflow-y-scroll">
                    {files.map((file, index) => (
                        <li key={file.source} className="relative">
                            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 mt-1 mb-1">
                                <img src={file.source} alt={file.title} className="pointer-events-none object-cover group-hover:opacity-75" />


                                <button
                                    type="button"
                                    className="absolute inset-0 focus:outline-none"
                                    onClick={() => openPopover(index)}
                                >
                                    <span className="sr-only">View details for {file.title}</span>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>

            {isPopoverOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-green-50 bg-opacity-50">
                    <div className="relative bg-white p-4 rounded-lg">
                        <XMarkIcon
                            aria-hidden="true"
                            className="absolute top-4 right-4 block h-6 w-6 cursor-pointer bg-black text-white rounded-full p-1 shadow-md"
                            onClick={closePopover}
                        />
                        <ChevronLeftIcon
                            aria-hidden="true"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 block h-6 w-6 cursor-pointer bg-black text-white rounded-full p-1 shadow-md"
                            onClick={previousImage}
                        />
                        <img
                            onClick={closePopover}
                            src={selectedImage} alt=""
                            className="object-contain max-h-[calc(100vh-4rem)] max-w-[calc(100vw-4rem)] rounded-lg"
                        />
                        <ChevronRightIcon
                            aria-hidden="true"
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 block h-6 w-6 cursor-pointer bg-black text-white rounded-full p-1 shadow-md"
                            onClick={nextImage}
                        />
                    </div>
                </div>
            )}
        </>
    )


};

export default Portfolio;