import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAudience } from '../utils/Actions';
import { Overlay } from '../components/Overlay';
import Armortech from '../components/Armortech';
import Armorcalltoaction from '../components/Armorcalltoaction';
import Virtualassistant from '../components/Virtualassistant';
import Armorvideotech from '../components/Armorvideotech';
import Submission from '../components/Submission';
import Calendly from '../components/Calendly';
import Reviews from '../components/Reviews';
import Armorportfolio from '../components/Armorportfolio';

function Armor({ formRef }) {

  const state = useSelector(state => state);
  const audience = useSelector(state => state.lead.audience);
  const dispatch = useDispatch();

  useEffect(() => { // update the state when the component is mounted
    dispatch(updateAudience({ ...audience, resurfacing: true }));
    return () => { // cleanup once the component is unmounted
      dispatch(updateAudience({ ...audience, resurfacing: false }));
    };
  }, []);

  return (

    <form>
      <Armorvideotech formRef={formRef} />
      <Armortech />
      <Armorportfolio />
      <Submission formRef={formRef} />
      <Reviews />
      <Armorcalltoaction formRef={formRef} />
      <Virtualassistant theme={'resurfacing'} />
    </form>
  );
}

export default Armor;