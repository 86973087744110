import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PhoneIcon } from '@heroicons/react/20/solid';

function Header({ formRef }) {

    const navigate = useNavigate(); // that hook is used to navigate to the home page if the header element is rendered on the page without submission form

    const estimator = useSelector(state => state.estimator);

    const scrolltoForm = (e) => {
        // Preventing the default behavior of the form submit (which is to refresh the page)
        e.preventDefault();
        if (formRef && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            // redirect to the home page if the formRef is not available
            navigate('/', { replace: true });
            
        };
            
    };
    return (
        <header className="bg-white">
            <div className="flex items-center justify-between px-4 py-4 sm:px-6 lg:px-8">
                <img className="h-16 w-auto" src={require('../assets/curb-ease-logo.jpg')} alt="Curb-Ease Logo" />
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                        <PhoneIcon className="h-6 w-6 text-green-700" aria-hidden="true" />
                        <a href={"tel:" + estimator.phone} className="text-green-700 font-bold hover:text-green-800">
                            {estimator.phone==='1-888-920-6317' ? 'Toll Free 1-888-920-6317' : estimator.phone}
                        </a>
                    </div>
                    <button
                        onClick={scrolltoForm}
                        className="min-w-[13rem] w-52 rounded-md bg-green-700 px-4 py-2 text-lg font-semibold text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2"
                    >
                        Get a Free Estimate
                    </button>
                </div>
            </div>

            <div
                className="relative flex flex-col items-center justify-end bg-cover bg-center bg-no-repeat h-64"
                style={{ backgroundImage: `url(${require('../assets/bckground.jpg')})` }}
            >
                <h1 className="text-white font-bold px-8 py-4 text-xl sm:text-2xl lg:text-3xl">
                    Extruded Concrete Curbing
                </h1>
                <p className="text-white font-bold px-8 pb-4 text-base sm:text-lg">
                    since 1996
                </p>
            </div>
        </header>
    );
}
export default Header;