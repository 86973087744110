import { createStore } from 'redux';
import { reducer } from './Reducers';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (e) {
    console.error('Could not load state:', e);
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch (e) {
    console.error('Could not save state:', e);
  }
};

const persistedState = loadState();
export const store = createStore(reducer, persistedState);

store.subscribe(() => {
  saveState(store.getState());
});