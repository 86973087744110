import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAudience } from '../utils/Actions';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Submission from '../components/Submission';

const features = [
    {
        name: '6”W x 4”H Low Angle',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x4LA.jpeg')}`,
    },
    {
        name: '6”W x 4”H Mower\'s Edge',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x4ME.jpg')}`,
    },
    {
        name: '6”W x 4”H Flat',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x4Flat.jpg')}`,
    },
    {
        name: '6”W x 4”H Peak',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x4Peak.jpg')}`,
    },
    {
        name: '6”W x 4”H Round',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x4Rnd.jpg')}`,
    },
    {
        name: '6”W x 6”H Tire Friendly',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x6TireFriendly.jpg')}`,
    },
    {
        name: '6”W x 6”H Square',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x6Square.jpg')}`,
    },
    {
        name: '6”W x 8”H Flat',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x8Flat.jpg')}`,
    },
    {
        name: '6”W x 12”H Flat',
        icon: CheckCircleIcon,
        source:
            `${require('../assets/gallery/profiles/6x12SQ.jpeg')}`,
    },
];

function Curbprofiles({ formRef }) {

    const audience = useSelector(state => state.lead.audience);
    const dispatch = useDispatch();

    useEffect(() => { // update the state when the component is mounted
        dispatch(updateAudience({ ...audience, curbing: true }));
        return () => { // cleanup once the component is unmounted
            dispatch(updateAudience({ ...audience, curbing: false }));
        };
    }, []);

    const scrolltoForm = (e) => {
        // Preventing the default behavior of the form submit (which is to refresh the page)
        e.preventDefault();
        if (formRef && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-3xl lg:text-left">
                        <h2 className="text-4xl font-semibold leading-7 text-green-700">Residential and Commercial Curb Profiles</h2>
                        <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                            Engineered to meet even the most demanding design requirements.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Curb-Ease® provides premium quality concrete in all applications. Most residential applications are done using integrally colored concrete.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Curb-Ease® uses Curber's Choice® iron oxide pigments which infuse the concrete with{' '}
                            <a
                                href="/color"
                                className="text-green-700 font-semibold hover:text-green-800"
                            >
                                rich, long-lasting, fade-resistant color.
                            </a>
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Our standard premium mix also includes additives designed to increase the strength and flexibility of our mix.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2 px-16 lg:px-32">
                            {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-lg font-bold leading-7 text-gray-900">
                                        <feature.icon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col ml-2 leading-7 text-gray-600 border-4 border-gray-300 rounded-lg">
                                        <img
                                            src={feature.source}
                                            alt={feature.name}
                                        />
                                        <p className="mt-4 ml-4">
                                            <a href="#"
                                                role="button"
                                                onClick={scrolltoForm}
                                                className="text-sm font-semibold leading-6 text-green-700 hover:text-green-800">
                                                Get an estimate <span aria-hidden="true">→</span>
                                            </a>
                                        </p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            <Submission formRef={formRef} />
        </>
    )

};

export default Curbprofiles;