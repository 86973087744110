import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAudience } from '../utils/Actions';
import { Overlay } from '../components/Overlay';
import Technology from '../components/Technology';
import Calltoaction from '../components/Calltoaction';
import Virtualassistant from '../components/Virtualassistant';
import Videotech from '../components/VideoTech';
import Submission from '../components/Submission';
import Calendly from '../components/Calendly';
import Reviews from '../components/Reviews';
import Portfolio from '../components/Portfolio';

function Contact({ formRef }) {

  const state = useSelector(state => state);
  const audience = useSelector(state => state.lead.audience);
  const dispatch = useDispatch();

  useEffect(() => { // update the state when the component is mounted
    dispatch(updateAudience({ ...audience, curbing: true }));
    return () => { // cleanup once the component is unmounted
      dispatch(updateAudience({ ...audience, curbing: false }));
    };
  }, []);

  return (
    <form>
      <Videotech formRef={formRef} />
      <Technology />
      <Portfolio />
      <Submission formRef={formRef} />
      <Reviews />
      <Calltoaction formRef={formRef} />
      <Virtualassistant theme={'curbing'} />
    </form>

  );
}

export default Contact;