import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const Armortech = () => {

    return (
        <div className="rounded-lg bg-gray-50">
            <p className="mt-4 font-bold text-center text-3xl sm:text-2xl">
                Why Concrete Resurfacing?
            </p>

            <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">

                    {/* First Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Better</p>
                            <p className="mt-1">
                                The old rule of thumb was old concrete with cracks, surface discoloration or imperfections must be removed and replaced. This is no longer necessary.
                                With a cement based polymer-modified overlay you can transform your drab concrete patio, driveway, pool deck, stairs and sidewalks into a new decorative colored concrete surface.
                            </p>
                        </div>
                    </div>

                    {/* Second Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Smarter</p>
                            <p className="mt-1">
                                In many cases this will save you a significant amount of money, conserve resources, eliminate disposal problems and can usually be done in a few days.
                            </p>
                        </div>
                    </div>

                    {/* Third Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Creative</p>
                            <p className="mt-1">
                                Armor-Crete® polymer-modified overlay system is one way to upgrade your concrete.
                                With a wide variety of colors and patterns available you are sure to select something to compliment your home.
                            </p>
                        </div>
                    </div>

                    {/* Fourth Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Attractive</p>
                            <p className="mt-1">
                                If your concrete is in good condition but just needs a facelift this is a great alternative to improve the appearance.
                            </p>
                        </div>
                    </div>

                    {/* Fifth Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Designed for outdoor applications</p>
                            <p className="mt-1">
                                The texture system is applied using a hopper gun. It can be colored to provide a durable, fade resistant finish and will revitalize deteriorated concrete.
                                This technique can also be applied on vertical surfaces such as steps or retaining walls.
                            </p>
                        </div>
                    </div>
                    {/* Sixth Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Not Slippery</p>
                            <p className="mt-1">
                                This system is often used around pool decks and large patios, walkways and driveways.
                                This is a functional concrete resurfacing system that provides a textured, slip resistant finish.
                                The final look is that similar to a stucco siding finish.
                                The system is a relatively cool surface for bare feet in the hot sun and creates a functional anti-slip surface for wet areas.
                            </p>
                        </div>
                    </div>

                    {/* Seventh Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Crack Repair</p>
                            <p className="mt-1">
                                The Armor-Crete® Crack Repair system has proven to be very successful for repairing structural moving cracks
                                prior to applying one of several Armor-Crete® resurfacing products.
                                There are basically two types of cracks; structural cracks and non-moving surface cracks.
                                Structural cracks go all the way through the substrate and begin or end at an edge or joint.
                                They can be less than 1/16th of an inch to more than one inch in width.
                                Structural cracks should always be repaired with the Armor-Crete® Crack Repair System.
                                Non-moving surface cracks start and stop in the middle of a slab and do not go all the way through the concrete substrate.
                                Non-moving cracks may not need to be repaired and can simply be covered up during the Armor-Crete® resurfacing application.
                            </p>
                        </div>
                    </div>

                    {/* Eighth Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Patch Repair</p>
                            <p className="mt-1">
                                Armor-Crete® Patch Repair material is used to repair holes and spalls. It can also be used for various other applications as listed below:
                            </p>
                            <ul className="list-disc ml-5 mt-1">
                                <li>Leveling coat to fill in low areas or to make a rough surface smooth</li>
                                <li>Re-pitching coat to correct the slope for proper drainage</li>
                                <li>As an underlayment for carpet, tile, or other types of flooring</li>
                                <li>To patch stucco and masonry</li>
                                <li>To eliminate tripping hazards at joints where one side of a concrete slab is raised</li>
                            </ul>
                            <p className="mt-1">
                                Armor-Crete® Patch Repair material can be applied from zero to 3" thick and sets in 20 minutes.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );


}
export default Armortech;