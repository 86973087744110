import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    updateCalendlyUrl,
    updateCalendly
} from '../utils/Actions';
import { getEnv } from '../utils/helpers';

const Gta = () => { // that component obtains the correct URL for Calendly in GTA and ensures Calendly after redirect

    const dispatch = useDispatch();
    const navigate = useNavigate(); // that hook is used to navigate to the home page after the state is updated

    useEffect(() => {
        const abc = async () => {
            dispatch(updateCalendlyUrl(await getEnv('CALGTA')));
            dispatch(updateCalendly(true)); // display calendly after the redirect
            navigate('/', { replace: true });
        };
        abc();
    });
    return null;
};

export default Gta;