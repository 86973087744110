
export const updateMessage = (header,result) => {
    return {
        type: 'updateMessage',
        payload: {header: header, result: result, style: (header==='Error') ? false : true}
    };
};

export const updateStatus = (status) => {
    return {
        type: 'updateStatus',
        payload: status
    };
};


export const updateLead = (lead) => {
    return {
        type: 'updateLead',
        payload: lead
    };
};

export const updateCalendly = (calendly) => {
    return {
        type: 'updateCalendly',
        payload: calendly
    };
};

export const updateCalendlyUrl = (url) => {
    return {
        type: 'updateCalendlyUrl',
        payload: url
    };
};

export const updateAudience = (audience) => {
    return {
        type: 'updateAudience',
        payload: audience
    };
};

export const updateAddress = (streetAddress, city, postalCode, region) => {
    return {
        type: 'updateAddress',
        payload: {streetAddress: streetAddress, city: city, postalCode: postalCode, region: region}
    };
};

export const updateEstimator = (estimator) => {
    return {
        type: 'updateEstimator',
        payload: estimator
    };
};

export const updateStateCached = (cached) => {
    return {
        type: 'updateCached',
        payload: cached
    };
};