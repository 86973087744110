export const initialState = {

    lead:
    {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        country: 'Canada',
        streetAddress: '',
        city: '',
        region: '',
        postalCode: '',
        audience:
        {
            curbing: false,
            resurfacing: false,
            grass: false,
            ottawa: false,
            golf: false,
            municipality: false
        },
        comment: '',
    },

    dummyLead:
    {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        country: 'Canada',
        streetAddress: '',
        city: '',
        region: '',
        postalCode: '',
        audience:
        {
            curbing: false,
            resurfacing: false,
            grass: false,
            ottawa: false,
            golf: false,
            municipality: false
        },
        comment: '',
    },

    message: { header: null, result: null, style: false }, // use style: false for error, true for success messages

    status: null, // displays dynamic status messages as the form is filled out

    calendly: false, // indicates if the calendly widget is visible

    calendlyUrl: 'https://calendly.com/corpora_rebuild06/curb-ease-estimate-com?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=279e27', // the url for the calendly widget, unique for each estimator calendar

    estimator:     { 
        id: 1, 
        name: 'Vasily', 
        photo: `${require('../assets/Vasily.jpeg')}`,
        phone: '1-888-920-6317',
        service: 'Servicing Newmarket, ON and surrounding areas',
        search: 'Curb-Ease® Newmarket',
        legal: `© 2024 Continuous Borders Inc. dba Curb-Ease®. All rights reserved. Fully insured. | Newmarket | Barrie | Toronto | Ottawa | Whitby | Oshawa | Ajax | Bradford | Georgina | Orillia | Ramara | Kawartha Lakes | Muskoka | Wasaga Beach | `,
        pricing: { curbs: '$18', resurfacing: '$10', grass: '$18' },
        calendar: {
            GTA: 'https://calendly.com/corpora_rebuild06/curb-ease-estimate-com?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=279e27',
            OTTAWA: 'https://calendly.com/won-torts_0r/curb-ease-estimate-com?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=279e27',
        },
    },

    cached: false, // indicates that the state has not been cached yet

};

export const estimators = [
    { 
        id: 1, 
        name: 'Vasily', 
        photo: `${require('../assets/Vasily.jpeg')}`,
        phone: '1-888-920-6317',
        service: 'Servicing Newmarket, ON and surrounding areas',
        search: 'Curb-Ease® Newmarket',
        legal: '© 2024 Continuous Borders Inc. dba Curb-Ease®. All rights reserved. Fully insured. | Newmarket | Barrie | Toronto | Ottawa | Whitby | Oshawa | Ajax | Bradford | Georgina | Orillia | Ramara | Kawartha Lakes | Muskoka | Wasaga Beach | ',
        pricing: { curbs: '$18', resurfacing: '$10', grass: '$18' },
        calendar: {
            GTA: 'https://calendly.com/corpora_rebuild06/curb-ease-estimate-com?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=279e27',
            OTTAWA: 'https://calendly.com/won-torts_0r/curb-ease-estimate-com?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=279e27',
        }, 
    },
    { 
        id: 2, 
        name: 'Garry', 
        photo: `${require('../assets/estimators/Garry.jpg')}`,
        phone: '1-604-463-5480',
        service: 'Corporate Crews servicing various locations in BC: Vancouver, Fraser Valley, Lower mainland, Vancouver Island',
        search: 'Curb-Ease® Corporate Office', 
    },
    { 
        id: 3, 
        name: 'Greg', 
        photo: `${require('../assets/estimators/Greg.jpeg')}`,
        phone: '1-250-398-2028', 
        service: 'Servicing the Cariboo Region (Williams Lake, Quesnel & 100 Mile House)',
        search: 'Curb-Ease® Cariboo',
    },
    { 
        id: 4, 
        name: 'Derrel and Karine', 
        photo: `${require('../assets/estimators/Derrel+&+Karine.jpeg')}`,
        phone: '1-604-463-5480', 
        service: 'Servicing Chilliwack, Abbotsford, BC',
        search: 'Curb-Ease® Chilliwack',
    },
    { 
        id: 5, 
        name: 'William and Charles', 
        photo: `${require('../assets/estimators/William+&+Charles.jpeg')}`,
        phone: '1-416-900-3895', 
        service: 'Servicing Etobicoke, ON and surrounding areas',
        search: 'Curb-Ease® Etobicoke',
    },
    { 
        id: 6, 
        name: 'David', 
        photo: `${require('../assets/estimators/David.jpeg')}`,
        phone: '1-780-851-1645', 
        service: 'Servicing Edmonton, AB',
        search: 'Curb-Ease® Edmonton',
    },
    { 
        id: 7, 
        name: 'Mackenzie and Andrew', 
        photo: `${require('../assets/estimators/Mackenzie+&+Andrew.jpeg')}`,
        phone: '1-587-802-0872',
        service: 'Servicing Red Deer, Alberta',
        search: 'Curb-Ease® Red Deer', 
    },
    { 
        id: 8, 
        name: 'Wes', 
        photo: `${require('../assets/estimators/Wes.jpeg')}`,
        phone: '1-403-770-9060', 
        service: 'Servicing Airdrie, Calgary',
        search: 'Curb-Ease® Airdrie',
    },
    { 
        id: 9, 
        name: 'Grant', 
        photo: `${require('../assets/estimators/Grant.jpeg')}`,
        phone: '1-403-770-9060',
        service: 'Servicing Strathmore, Okotoks, Calgary',
        search: 'Curb-Ease® Strathmore', 
    },
    { 
        id: 10, 
        name: 'Mario and Paul', 
        photo: `${require('../assets/estimators/Mario+&+Paul.jpeg')}`,
        phone: '1-910-300-9345',
        service: 'Servicing Wilmington, NC and surrounding areas',
        search: 'Curb-Ease® Wilmington', 
    },
];