import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAudience } from '../utils/Actions';
import Virtualassistant from '../components/Virtualassistant';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const features = [
    {
        name: 'Everblade 50 Landscape Turf',
        description:
            'For use in light to moderate traffic areas. This premium turf gives all the strength expected in an 50 oz product but with extra softness and comfort. Specially designed to keep surface temperatures low. Great for landscaping applications and playgrounds.',
        description1: 'Colour: Emerald / Lime Green',
        description2: 'Yarn Type: Flat Shape w/ Golden Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/evbl50.png')}`,
        source1: `${require('../assets/grass/wnt15.png')}`
    },
    {
        name: 'Durablade 65 Landscape Turf',
        description:
            'For use in moderate to heavy traffic areas. Designed to truly replicate grass. “S” shape 3D fibre is specially formulated to reduce heat retention. Designed specifically for landscape application with a soft touch feel.',
        description1: 'Colour: Emerald / Olive Green',
        description2: 'Yarn Type: Flat Shape w/ Golden Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/dbl65.png')}`,
        source1: `${require('../assets/grass/wnt15.png')}`
    },
    {
        name: 'Triblend 50 Landscape Turf',
        description:
            'For use in Moderate to heavy traffic. The NEW Triblend 50 is designed with Multi 3D shaped fibers. “W”, “V” and Flat shaped fibers create a durable surface that looks and feels very natural. Triblend is enriched with our bi colour thatch system to create stability and support.',
        description1: 'Colour: Emerald / Olive Green / Sand',
        description2: 'Yarn Type: Flat, W and V Shape w/ Tan/Green Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/tbl50.png')}`,
        source1: `${require('../assets/grass/wnt15.png')}`
    },
    {
        name: 'Everblade 80 Landscape Turf',
        description:
            'For use in moderate to high traffic areas. This premium turf gives all the strength expected in an 80 oz product but with extra softness and comfort. Specially designed to keep surfac temperatures low. Great for landscaping applications and playgrounds.',
        description1: 'Colour: Emerald / Lime Green',
        description2: 'Yarn Type: Flat Shape w/ Golden Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/ebl80.png')}`,
        source1: `${require('../assets/grass/wnt15.png')}`
    },
    {
        name: 'Triblend 70 Landscape Turf',
        description:
            'This multi 3D shaped turf includes unique “W”, “V” and flat shaped fibres that creates a durable and natural feel. A blend of Emerald, Olive and Sand colour tones replicate an organic looking lawn. Each fibre is unique in colour and size creating a blend like no other turf on the market today. Triblend is enriched with our Bi colour thatch system to create stability and support.',
        description1: 'Colour: Emerald / Olive Green / Sand',
        description2: 'Yarn Type: Flat, W and V Shape w/ Tan/Green Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/tbl70.png')}`,
        source1: `${require('../assets/grass/wnt15.png')}`
    },
    {
        name: 'Ultrablade 50 Landscape Turf',
        description:
            'For use in Moderate to heavy traffic. Ultrablade 50 is developed with W shaped fibers to enhance the durability of the turf. The 2-tone colour and shape of the fiber also gives the surface a natural matt finish. The look and feel of Ultrablade 50 is a nice balance between soft and firm. Included is our standard bi-colour thatch layer for fiber support.',
        description1: 'Colour: Field / Olive Green',
        description2: 'Yarn Type: Double W Shape w/ Golden Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/ubl50.png')}`,
        source1: `${require('../assets/grass/wnt15.png')}`
    },
    {
        name: 'Durapet Pet Turf',
        description:
            'For use in moderate to high traffic areas. This uniquely designed 3D fiber is “S” shaped that allows for great rebound and strength. Durapet allows for fast and efficient drainage and is specifically designed for pet areas, putting green fringe and landscaping applications.',
        description1: 'Colour: Emerald / Olive Bi-Colour',
        description2: 'Yarn Type: S Shape w/ Golden Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/dpt.png')}`,
        source1: `${require('../assets/grass/wnt15.png')}`
    },
    {
        name: 'Tropical 40 Landscape Turf',
        description:
            'For use in light to moderate traffic areas. Designed for easy portability. This lightweight economy-priced turf offers great value and flexibility to meet everyone’s budget. Uniquely formulated polyurethane coated backing provides greater seam strength and durability.',
        description1: 'Colour: Field / Clover Green',
        description2: 'Yarn Type: S Shape w/ Golden Thatch Layer',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/tpc40.png')}`,
        source1: `${require('../assets/grass/wnt8.png')}`
    },
    {
        name: 'Pro Putt Golf Turf',
        description:
            'For use in custom-designed putting greens. This premium turf is designed to give true ball roll and bounce reaction. Infill is required to control stint speed and ball bounce/roll. Specially designed for our Canadian climate. Lots of fun and great for training.',
        description1: 'Colour: Field Green / Lime Green',
        description2: 'Pile Height: 0.5”',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/ppt.png')}`,
        source1: `${require('../assets/grass/wnt8.png')}`
    },
    {
        name: 'Bermuda Pro Golf Turf (Infill Required)',
        description:
            'The champion of realism when it comes to play and feel. Bermuda Pro is an elite surface that reacts naturally with impact from distance shots and short chips. The perfect density of fibre combination and infill sand makes the ball react naturally on impact and when putting on the green. PGA professionals and high level golfers enjoy the natural experience that brings to your home or training facility. Our colour blend of field green and emerald creates a life like feel on and off the putting green.',
        description1: 'Colour: Field Green / Emerald',
        description2: 'Pile Height: 95”',
        href: '#',
        icon: CheckCircleIcon,
        source: `${require('../assets/grass/bmd.png')}`,
        source1: `${require('../assets/grass/wnt8.png')}`
    },

];


export default function Grass({ formRef }) {
    const state = useSelector(state => state);
    const audience = useSelector(state => state.lead.audience);
    const dispatch = useDispatch();

    useEffect(() => { // update the state when the component is mounted
        dispatch(updateAudience({ ...audience, grass: true }));
        return () => { // cleanup once the component is unmounted
            dispatch(updateAudience({ ...audience, grass: false }));
        };
    }, []);

    const scrolltoForm = (e) => {
        // Preventing the default behavior of the form submit (which is to refresh the page)
        e.preventDefault();
        if (formRef && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-left">
                        <h2 className="text-4xl font-semibold leading-7 text-green-700">Artificial Grass - Landscape and Golf </h2>
                        <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                            No mowing No watering No fertilizing No weeding No kidding. Great for pets.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            With over 25 years of experience in the curbing business, Curb-Ease® combines
                            {' '}artificial grass installations with beautiful decorative concrete edging to enhance your outdoor space.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Artificial grass simplifies lawn maintenance, while our decorative curbing adds a stylish, durable border that
                            {' '}elegantly separates flower beds from your lawn. These garden beds can house vibrant, natural plants, providing
                            {' '}the perfect balance between lush greenery and hassle-free upkeep.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2 px-16 lg:px-32">
                            {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col border-4 border-gray-300 rounded-lg">
                                    <dt className="flex items-center gap-x-3 text-lg font-bold leading-7 text-gray-900">
                                        <feature.icon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col ml-2 leading-7 text-gray-600">
                                        <img
                                            src={feature.source}
                                            className='w-50 h-auto'
                                            alt={feature.name}
                                        />
                                        <p className="flex-auto">{feature.description}</p>
                                        <p className="flex-auto text-sm">{feature.description1}</p>
                                        <p className="flex-auto text-sm">{feature.description2}</p>
                                        <img
                                            src={feature.source1}
                                            className='w-20 h-auto ml-auto'
                                            alt='Warranty 15 years'
                                        />
                                        <p className="mt-6">
                                            <a href={feature.href}
                                                role="button"
                                                onClick={scrolltoForm}
                                                className="text-sm font-semibold leading-6 text-green-700 hover:text-green-800">
                                                Get a Free Estimate <span aria-hidden="true">→</span>
                                            </a>
                                        </p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
            <Virtualassistant theme={'grass'} />
        </>

    )
}
